body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(216, 188, 241);
  border: 8px solid black;
}

#quote-box {
  padding: 35px;
  width: 400px;
  border-radius: 5px;
  background-color: white;
}

#text {
  font-family: Noto;
  font-size: 32px;
  text-align: center;
  transition: opacity 0.3s linear 2s;
}

#author{
  text-align: right;
  padding: 8px;
  font-style: italic;
}

#new-quote {
  padding: 0px 16px;
  margin-top: 8px;
  border-radius: 5px;
  border-style: none;
  color: white;
  font-weight: bold;
}

#tweet-quote {
  font-size: 48px;
}

#buttons {
  display: flex;
  justify-content: space-between;
  height: 48px;
}

.fade-wrapper {
  transition: opacity ease-in-out;
}
.fade-out {
  opacity: 0;
}
.fade-in {
  opacity: 1;
}

